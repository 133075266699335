import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-47dfb14e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "source"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  var _component_Head = _resolveComponent("Head");

  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  var _component_Pagination = _resolveComponent("Pagination");

  var _component_Tabs = _resolveComponent("Tabs");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "工作计划/总结"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_el_select, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.value = $event;
        }),
        class: "dj-select",
        placeholder: "请选择分类",
        onChange: _ctx.selectChange
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (item) {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.value,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue", "onChange"]), [[_vShow, _ctx.nowTab == 0]]), _withDirectives(_createElementVNode("div", {
        class: "spacialBtn",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.addworkself();
        })
      }, " 添加工作计划/总结 ", 512), [[_vShow, _ctx.nowTab == 1]])];
    }),
    _: 1
  }), _createVNode(_component_Tabs, {
    title: _ctx.titleArr,
    onChangeOther: _ctx.changeOther
  }, {
    decTit: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_withDirectives(_createElementVNode("span", {
        class: "num"
      }, _toDisplayString(_ctx.tableData.arr.length) + "个", 513), [[_vShow, item.index == 0 && _ctx.tableData.arr.length > 0]])];
    }),
    content0: _withCtx(function () {
      return [_createVNode(_component_el_table, {
        data: _ctx.tableData.arr,
        style: {
          "width": "100%"
        },
        "empty-text": "暂无数据",
        class: "el-table-index"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_table_column, {
            width: "500"
          }, {
            default: _withCtx(function (scoped) {
              return [_createElementVNode("span", _hoisted_1, _toDisplayString(scoped.row.launchType == 2 ? _ctx.source : ""), 1), _createElementVNode("span", {
                style: _normalizeStyle({
                  color: scoped.row.status == 3 ? '#f09104' : '',
                  cursor: 'default'
                })
              }, _toDisplayString(scoped.row.title), 5)];
            }),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "startTime",
            width: "200"
          }), _createVNode(_component_el_table_column, null, {
            default: _withCtx(function (scoped) {
              return [_createElementVNode("span", {
                class: "btn-detail",
                onClick: function onClick($event) {
                  return _ctx.godetail(scoped.row.recordId, 0);
                }
              }, "查看详情", 8, _hoisted_2)];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["data"]), _createVNode(_component_Pagination, {
        pagesize: 10,
        "total-num": _ctx.totalNum,
        onChangPage: _ctx.changPage
      }, null, 8, ["total-num", "onChangPage"])];
    }),
    content1: _withCtx(function () {
      return [_createVNode(_component_el_table, {
        data: _ctx.tableDataTwo.arr,
        style: {
          "width": "100%"
        },
        "empty-text": "暂无数据",
        class: "el-table-index"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_table_column, {
            prop: "title",
            width: "400"
          }), _createVNode(_component_el_table_column, {
            prop: "modifiedTime"
          }), _createVNode(_component_el_table_column, null, {
            default: _withCtx(function (scoped) {
              return [_createElementVNode("span", {
                class: "btn-detail",
                onClick: function onClick($event) {
                  return _ctx.godetail(scoped.row.recordId, 1);
                }
              }, "查看详情", 8, _hoisted_3), _createElementVNode("span", {
                class: "btn-edit",
                onClick: function onClick($event) {
                  return _ctx.addworkself(scoped.row.recordId);
                }
              }, "编辑", 8, _hoisted_4), _createElementVNode("span", {
                class: "btn-del",
                onClick: function onClick($event) {
                  return _ctx.workselfDel(scoped.row.recordId, 0);
                }
              }, "删除", 8, _hoisted_5)];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["data"]), _createVNode(_component_Pagination, {
        pagesize: 10,
        "total-num": _ctx.totalNumTwo,
        onChangPage: _ctx.changPageTwo
      }, null, 8, ["total-num", "onChangPage"])];
    }),
    _: 1
  }, 8, ["title", "onChangeOther"])], 64);
}